.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.aclass {
  color: #0087c3;
  text-decoration: none;
}

.bodyclass {
  position: relative;
  width: 21cm;
  height: 29.7cm;
  margin: 0 auto;
  color: #555555;
  background: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-family: SourceSansPro;
}

#logo {
  float: left;
  margin-top: 8px;
}

#logo img {
  height: 70px;
}

#company {
  float: right;
  text-align: right;
}

#details {
  margin-bottom: 50px;
}

#client {
  padding-left: 6px;
  border-left: 6px solid #0087c3;
  float: left;
}

#client .to {
  color: #777777;
}

h2.name {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0;
}

#invoice {
  float: right;
  text-align: right;
}

#invoice h5 {
  color: #0087c3;
  line-height: 1em;
  font-weight: normal;
  margin: 0 0 10px 0;
}

#invoice .date {
  font-size: 1.1em;
  color: #777777;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.bodyclass .table th,
.bodyclass .table td {
  padding: 20px;
  background: #eeeeee;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

.table th {
  white-space: nowrap;
  font-weight: normal;
}

.table td {
  text-align: right;
}

.table td h3 {
  color: #57b223;
  font-size: 1em;
  font-weight: normal;
  margin: 0 0 0.2em 0;
}

.table .no {
  color: #ffffff;
  font-size: 1.6em;
  background: #57b223;
}

.table .desc {
  text-align: left;
}

.table .unit {
  // background: #DDDDDD;
}

.table .qty {
}

.table .total {
  background: #57b223;
  color: #ffffff;
}

.table td.unit,
.table td.desc,
.table td.no,
.table td.qty,
.table td.total {
  font-size: 1em;
}

.table tbody tr:last-child td {
  border: none;
}

.table tfoot td {
  padding: 10px 20px;
  background: #ffffff;
  border-bottom: none;
  font-size: 1.2em;
  white-space: nowrap;
  border-top: 1px solid #aaaaaa;
}

.table tfoot tr:first-child td {
  border-top: none;
}

.table tfoot tr td:first-child {
  border: none;
}

#thanks {
  font-size: 2em;
  margin-bottom: 50px;
}

#notices {
  padding-left: 6px;
  border-left: 6px solid #0087c3;
}

#notices .notice {
  font-size: 1.2em;
}

footer {
  color: #777777;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #aaaaaa;
  padding: 8px 0;
  text-align: center;
}
.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #ccc;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #ccc;
  left: 8px;
  top: 12px;
}

