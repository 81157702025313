body {
  margin: 0px;
}

body .makeStyles-adminRoot-1,
html .makeStyles-adminRoot-1,
.MuiPopover-root,
.MuiDialog-paper,
.Toastify,
.MuiDialog-paperFullScreen {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.medentry {
  border-bottom: 1px solid red !important;
}

.medentry:before {
  border-bottom: 1px solid red !important;
}

.medentry:hover {
  border-bottom: 1px solid red !important;
}

.medentry:after {
  border-bottom: 1px solid red !important;
}

@media only screen and (min-width: 960px) {
  .imageViwer {
    display: flex !important;
    overflow: auto !important;
    height: 80vh !important;
  }

  .imageCard {
    height: 820px;
    width: 100%;
    overflow: auto;
  }
}
@media only screen and (max-width: 960px) {
  .nextIcon {
    position: absolute;
    z-index: 1;
    right: 0%;
    top: 50%;
  }
  .prevIcon {
    position: absolute;
    z-index: 1;
    left: 0%;
    top: 50%;
  }
}
@media only screen and (min-width: 960px) {
  .nextIcon {
    position: absolute;
    z-index: 1;
    right: 52%;
    top: 60%;
  }
  .prevIcon {
    position: absolute;
    z-index: 1;
    top: 60%;
  }
}

@media only screen and (max-width: 960px) {
  .imageViwer {
    display: flex !important;
    overflow: auto !important;
    height: 300px !important;
  }

  .formViwer {
    // display: flex !important;
    overflow: auto !important;
    height: 300px !important;
  }

  .imageCard {
    height: 500px;
    width: 100%;
    overflow: auto;
  }
}

// User View Tab fixed for different devices

@media only screen and (max-width: 960px) {
  .userTableViewTabs {
    margin-top: 80px;
    // position: relative;
    // left: 0%;
  }
}

@media only screen and (min-width: 960px) {
  .userTableViewTabs {
    margin-top: 80px;
    margin-left: 270px;
    // position: relative;
    // left: 25%;
  }
}

.DayPicker-wrapper {
  width: max-content !important;
}

.customLabelWidth .MuiFormLabel-root {
  width: max-content !important;
}

.MuiPickerDTToolbar-toolbar .MuiButton-label {
  padding: 0 !important;
}

.prescriptionImageStyle .MuiTab-wrapper {
  padding: 2px;
  background-image: linear-gradient(
    30deg,
    #fc575e 0%,
    #f7b42c 15%,
    #f7b42c 100%
  );
}

.prescriptionImageStyle .Mui-selected .MuiTab-wrapper {
  background-image: linear-gradient(
    30deg,
    #6da5de 0%,
    #6da5de 15%,
    #7de3dc 100%
  );
}
.dummyPage .MuiListItemIcon-root {
  min-width: 32px !important;
  // margin-top: 10px !important;
}

.processCaseEditTab .MuiTabs-scrollButtons {
  background-color: #fff !important;
  border-radius: 70% !important;
  color: #000 !important;
}

.MuiTab-textColorInherit {
  min-width: auto !important;
}

.MuiTab-textColorInherit .Mui-selected {
  min-width: auto !important;
}
