@font-face {
  font-family: Segoe UI;
  src: url(../src/assets/fontFamily/Segoe\ UI.woff);
}
body {
  font-family: "Segoe UI" !important;
}
.css-10hburv-MuiTypography-root {
  font-family: "Segoe UI" !important;
}
div.MuiBox-root {
  font-family: "Segoe UI" !important;
}

/* Add global styles for input labels */
.MuiInputLabel-root {
  font-family: "Segoe UI" !important;
}

/* Add global styles for text fields */
.MuiTextField-root {
  font-family: "Segoe UI" !important;
}
.MuiInputBase-root {
  border: 1px solid #d9d9d9;
}

input[type="text"],
input[type="password"],
textarea {
  font-family: "Segoe UI" !important;
}

#myButton .MuiButton-label {
  font-family: "Segoe UI" !important;
}

.MuiListItemText-primary {
  font-weight: 600 !important;
}

.segoe-input {
  font-family: "Segoe UI";
}


/* Assuming you have a CSS class called 'scrollable-content' */
.scrollable-content::-webkit-scrollbar {
  width: 4px; /* Adjust the width as desired */
  height: 3px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Adjust the track background color as desired */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: #b8b4b4; /* Adjust the thumb color as desired */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #c1bfbf; /* Adjust the thumb color on hover as desired */
}

.scrollable-cell {
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}